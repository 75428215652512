import React from "react";

export default function Photos() {
  return (
    <div>
      <div className="janitor-manager-mobile">
        <img
          src="janman-poster.JPG"
          alt="Janitor/Manager poster"
          style={{ width: "350px", height: "500px" }}
        />
        <img
          src="seanrad-asheville.jpeg"
          alt="Asheville poster"
          style={{ width: "350px", height: "500px" }}
        />
      </div>
    </div>
  );
}
