import React, { useState } from "react";
import "./App.css";
import video from "./media/baller.mp4";
import YouTube from "react-youtube";
import About from "./pages/About";
import Videos from "./pages/Videos";
import Photos from "./pages/Photos";
import Headshot from "./pages/Headshot";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Navigation from "./pages/Navigation";

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const handleBannerClick = () => {
    navigate("/");
  };
  const [pageLoaded, setPageLoaded] = useState(false);

  const opts = {
    height: "400",
    width: "600",
    playerVars: {
      autoplay: 0,
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const handleMainVideoEnd = () => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 500);
  };

  return (
    <div className="App">
      {!pageLoaded && (
        <div className="baller-video">
          <video
            src={video}
            width="600"
            height="300"
            autoPlay={true}
            muted
            playsInline
            onEnded={handleMainVideoEnd}
          />
        </div>
      )}

      {pageLoaded && (
        <>
          <div className="background">
            <div className="desktop-banner">
              <img
                src="desktopbanner.png"
                alt="desktop banner"
                onClick={handleBannerClick}
              ></img>
            </div>
            <div className="mobile">
              <img
                className="mobile-banner"
                src="mobilebanner.png"
                alt="mobile banner"
                onClick={handleBannerClick}
              ></img>
            </div>

            <div className="main-body">
              <h1>What's up mofos 👋</h1>
              <br />
              <p>
                Sean Conrad is a stand up comedian and writer based in NYC. He
                can be seen at clubs around NYC such as Eastville, Broadway, and
                Saint Marks Comedy Club. Most recently, he performed his show
                "Janitor/Manager" at the 2023 Edinburgh Fringe Festival where
                one reviewer described him as "objectively funny" (take that,
                hecklers)! His award-winning films have screened at festivals
                across the country. Formerly, he hosted "Shining A Light",
                produced by Academy Award-winning Shine Global, which fosters
                conversations with prominent youth activists from around the
                world. See below for ✨comedy content✨ For business queries and
                bookings{" "}
                <a
                  href="https://forms.gle/2pHGc3m3p79B1zKS9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#2FBF9E" }}
                >
                  contact Sean here.
                </a>
              </p>
            </div>
          </div>

          <Navigation />
          <Routes>
            <Route path="/" />
            <Route path="/about" element={<About />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/photos" element={<Photos />} />
          </Routes>

          <div className="tight5">
            <YouTube videoId="XXBzAQV4Mik" opts={opts} />
          </div>

          <div className="stmarks">
            <YouTube videoId="dSoKk1UUSwA" opts={opts} />
          </div>
          <div className="tight5">
            <YouTube videoId="KeSXptsvwqY" opts={opts} />
          </div>
          <div className="janitor-manager">
            <img
              src="janman-poster.JPG"
              alt="Janitor/Manager poster"
              style={{ width: "400px", height: "500px" }}
            />
            <img
              src="seanrad-asheville.jpeg"
              alt="Asheville poster"
              style={{ width: "400px", height: "500px" }}
            />
          </div>
          <div className="icons">
            <a
              href="https://www.youtube.com/channel/UCVeTDMT76Lx1Xtd0dSHCQPA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="youtube_icon.png"
                alt="youtube icon"
                style={{ width: "50px", height: "50px" }}
              />
            </a>
            <a
              href="https://www.instagram.com/seanradcomedy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="Instagram_icon.png"
                alt="instagram icon"
                style={{ width: "50px", height: "50px" }}
              />
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
