import React from "react";
import YouTube from "react-youtube";

export default function Videos() {
  const opts0 = {
    height: "400",
    width: "300",
    playerVars: {
      autoplay: 0,
      // https://developers.google.com/youtube/player_parameters
    },
  };

  return (
    <div>
      <div className="tight5mobile">
        <YouTube videoId="XXBzAQV4Mik" opts={opts0} />
      </div>
      <div className="tight5mobile">
        <YouTube videoId="dSoKk1UUSwA" opts={opts0} />
      </div>
      <div className="tight5mobile">
        <YouTube videoId="KeSXptsvwqY" opts={opts0} />
      </div>
    </div>
  );
}
