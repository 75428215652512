import { Link } from "react-router-dom";
import React from "react";

function Navigation() {
  return (
    <nav className="clips">
      <h1>
        <Link to="/">Home</Link>
      </h1>
      <h1>
        <Link to="/about">About</Link>
      </h1>
      <h1>
        <Link to="/Videos">Videos</Link>
      </h1>
      <h1>
        <Link to="/photos">Photos</Link>
      </h1>
    </nav>
  );
}

export default Navigation;
