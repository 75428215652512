import React from "react";

export default function About() {
  return (
    <div className="about">
      <p>
        Sean Conrad is a stand up comedian and writer based in NYC. He can be
        seen at clubs around NYC such as Eastville, Broadway, and Saint Marks
        Comedy Club. Most recently, he performed his show "Janitor/Manager" at
        the 2023 Edinburgh Fringe Festival where one reviewer described him as
        "objectively funny" (take that, hecklers)! His award-winning films have
        screened at festivals across the country. Formerly, he hosted "Shining A
        Light", produced by Academy Award-winning Shine Global, which fosters
        conversations with prominent youth activists from around the world. See
        below for ✨comedy content✨ For business queries and bookings{" "}
        <a
          href="https://forms.gle/2pHGc3m3p79B1zKS9"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#2FBF9E" }}
        >
          contact Sean here.
        </a>
      </p>
    </div>
  );
}
